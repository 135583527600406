import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <div className="content-holder" style={{ textAlign: "center" }}>
            <h1>Page Not Found!</h1>
            <p>
                You just hit a route that doesn&#39;t exist...
                <br />
                Please use the navigation of this website.
            </p>
        </div>
    </Layout>
);

export default NotFoundPage;
